import { Fragment, FunctionalComponent, h } from "preact";
import * as Dialog from "@radix-ui/react-dialog";
import { AdditionalServices, Subscription, voiceShopStoreStateType } from "../../shop-types";
import { ServiceExpandable } from "../../../Expandable/service-expandable";
import { Price } from "@shared/Components";
import { isEmptyString } from "@shared/Utils";
import { ProductBlockDataModel, ProductServiceBlockModel } from "../../Helpers/subscription-builder.types";
import { FunnelStep, sendGA4FunnelData } from "@shared/Analytics/content/funnel-analytics";

export interface AddServiceDialogProps {
  useVoiceStore: voiceShopStoreStateType;
  productData: ProductBlockDataModel;
  offeringsId: string;
  additionalServices: AdditionalServices[];
}

export const AddserviceDialog: FunctionalComponent<AddServiceDialogProps> = ({
  useVoiceStore,
  productData,
  offeringsId,
  additionalServices,
}: AddServiceDialogProps) => {
  return (
    <Fragment>
      <Dialog.Title className="no-focus-outline addservice-dialog__title">{productData.serviceAddTitle}</Dialog.Title>
      {productData.serviceAddDesc && (
        <Dialog.Description className="addservice-dialog__description">{productData.serviceAddDesc}</Dialog.Description>
      )}
      <ul className={`list--unstyled addservice-dialog__items mt-2`}>
        {productData.subscriptionServices?.map((service) => (
          <AddServiceDialogItem
            key={service.productId}
            service={service}
            useVoiceStore={useVoiceStore}
            offeringsId={offeringsId}
            additionalServices={additionalServices}
          />
        ))}
      </ul>

      <Dialog.Close asChild>
        <div className="flex flex--row-center mt-2">
          <button className="btn btn--small btn--secondary addservice-dialog__close">Lukk</button>
        </div>
      </Dialog.Close>
    </Fragment>
  );
};

export interface AddServiceDialogItemProps {
  service: ProductServiceBlockModel;
  useVoiceStore: voiceShopStoreStateType;
  offeringsId: string;
  additionalServices: AdditionalServices[];
}

export const AddServiceDialogItem: FunctionalComponent<AddServiceDialogItemProps> = ({
  service,
  useVoiceStore,
  offeringsId,
  additionalServices,
}: AddServiceDialogItemProps) => {
  const servicefromStore = additionalServices.find((x) => x.serviceId === service.productId);

  const updateService = useVoiceStore((state) => state.updateService);
  const update = async () => {
    updateService(offeringsId, service.productId, !servicefromStore.selected);

    const offeringsList = useVoiceStore.getState().offerings as Subscription[];

    await sendGA4FunnelData(offeringsList, FunnelStep.Tilleggsvelger);
  };

  return (
    <li
      key={service.title}
      name={service.title}
      className={`addservice-dialog__item ${servicefromStore.selected ? "addservice-dialog__item--selected" : ""}`}
    >
      <div className="flex flex--split">
        <div>
          <div className="text--bold-700">{isEmptyString(service.title) ? service.name : service.title}</div>
          <div>
            <Price amount={service.price} useKr={true} />
            {` /mnd`}
          </div>
        </div>
        <span>
          <button className={servicefromStore.selected ? "link mr-1" : "btn addservice-dialog__btn"} onClick={update}>
            {!servicefromStore.selected ? "Legg til" : "Fjern"}
          </button>
          {servicefromStore.selected && (
            <div className="addservice-dialog__btn addservice-dialog__btn--added">Lagt til</div>
          )}
        </span>
      </div>
      <div>
        <br />
        <ServiceExpandable
          contentingress={service.serviceInfoTitle}
          readmoretext="Les mer"
          closetext="Lukk"
          ariabuttonText={service.title}
          content={service.serviceInfoContent}
        ></ServiceExpandable>
      </div>
    </li>
  );
};
