import { MbbBundle } from "Scripts/Src/Components/ShoppingCart/shop-types";
import {
  GA4EventTypes,
  GA4Product,
  GAEventTypes,
  GTMProduct,
  createProductCategories,
} from "./mbb-b2c-analytics.models";
import { clearPreviousEcommerceObjectToDataLayer, pushToDataLayer } from "@shared/Analytics/data/data-layer.facade";
import { addIndexToItems } from "@shared/Utils/analytics-utils";

const getBundleForSubscriptionAndRouter = (bundle: MbbBundle) => {
  const subscription: GTMProduct = {
    id: bundle.planId,
    name: "Mobilt Bredbånd",
    variant: `MBB ${bundle.subscriptionname}`,
    brand: "ICE",
    category: "Service/MBB/data",
    price: bundle.monthlypricenocampaign,
    dimension6: "faktura",
    dimension10: "Mobilt Bredbånd - Router",
    dimension18: 12,
  };

  const router: GTMProduct = {
    id: bundle.routerId,
    name: bundle.routername,
    price: bundle.routerprice,
    variant: bundle.routername,
    brand: "ICE",
    category: "Hardware/Router",
    dimension6: "faktura",
    dimension10: "Mobilt Bredbånd - Router",
    dimension18: 12,
  };

  return [subscription, router] as const;
};
export const getGA4BundleForSubscriptionAndRouter = (bundle: MbbBundle) => {
  const subscription: GA4Product = {
    item_id: bundle.planId,
    item_name: `Mobilt Bredbånd ${bundle.name}`,
    item_variant: `MBB ${bundle.subscriptionname}`,
    item_brand: "Ice",
    item_bundle: "Mobilt Bredbånd",
    affiliation: "ice.no",
    item_business_unit: getBusinessDomain(bundle),
    quantity: 1,
    price: calculateProductItemPrice(bundle.monthlyprice),
    ...createProductCategories("Service", "MBB", "data"),
  };

  const router: GA4Product = {
    item_id: bundle.routerId,
    item_name: bundle.routername,
    affiliation: "ice.no",
    item_business_unit: getBusinessDomain(bundle),
    item_variant: bundle.routername,
    item_bundle: "Mobilt Bredbånd",
    item_brand: "Ice",
    item_product_type: "Router",
    quantity: 1,
    price: bundle.routerprice, // One time purchases are not multiplied by 24
    ...createProductCategories("Hardware", "Router"),
  };

  const shipping: GA4Product = {
    item_id: "", // not currently available
    item_name: "Frakt",
    item_brand: "Ice",
    item_binding: "Nei",
    item_bundle: "Mobilt Bredbånd",
    item_business_unit: getBusinessDomain(bundle),
    item_product_type: "Service",
    item_variant: "Frakt",
    affiliation: "ice.no",
    quantity: 1,
    price: 99,
    ...createProductCategories("Service", "Addon"),
  };

  return [subscription, router, shipping];
};

const getBusinessDomain = (subscription: MbbBundle) => {
  return subscription.isbusiness === true ? "B2B" : "B2C";
};

const calculateProductItemPrice = (productPrice: number) => {
  const price = productPrice * 24;
  return parseInt(price.toFixed(2), 10);
};

const calculateMBBValue = (products: GA4Product[]) => {
  const value = products.reduce((sum, { price }) => sum + price, 0);
  return parseInt(value.toFixed(2), 10);
};

export const sendGAEvent = (eventType: GAEventTypes, subscription: MbbBundle) => {
  clearPreviousEcommerceObjectToDataLayer();

  const [sub, router] = getBundleForSubscriptionAndRouter(subscription);

  pushToDataLayer({
    event: eventType,
    eventAction: eventType,
    eventCategory: "ecommerce",
    eventLabel: subscription.isbusiness === true ? "mbb bedrift" : "mbb privat",
    ecommerce: {
      currencyCode: "NOK",
      add: [sub, router],
    },
  });
};

/* GA4 implementation  */
export const sendGA4Event = (eventType: GA4EventTypes, subscription: MbbBundle) => {
  clearPreviousEcommerceObjectToDataLayer();

  const items = getGA4BundleForSubscriptionAndRouter(subscription);

  pushToDataLayer({
    event: eventType,
    ecommerce: {
      currency: "NOK",
      value: calculateMBBValue(items),
      items: addIndexToItems(items),
    },
  });
};
