import { h, type FunctionalComponent, Fragment } from "preact";
import { Price } from "@shared/Components";
import type { MbbBundle } from "../shop-types";
import { MobileBroadbandIcon } from "../Helpers/Icons";

type MbbSubscriptionProps = {
  sub: MbbBundle;
  removeOffering: (id: string) => void;
};

export const MbbSubscriptionView: FunctionalComponent<MbbSubscriptionProps> = ({
  removeOffering,
  sub,
}: MbbSubscriptionProps) => {
  const removeOfferingHandler = (event: Event): void => {
    event.preventDefault();
    removeOffering(sub.offeringId);
  };

  return (
    <Fragment>
      <li className="subscriptions__block">
        <div className="subscriptions__block__inner  subscriptions__block__inner_mbb ">
          <div>
            <div className="flex flex--split">
              <span className="text--bold-700">{sub.name}</span>
              <span className="visually-hidden">{`abonnement som koster ${sub.monthlyprice} kroner per måned`}</span>
              <button
                type="button"
                id={`remove_${sub.name.replace(" ", "")}_${sub.offeringId}`}
                className="subscriptions__block__btn link"
                onClick={(e): void => removeOfferingHandler(e)}
                aria-label={`Fjern abonnementet ${sub.name}`}
              >
                Fjern{" "}
                <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
                  <use xlinkHref="#ice-trash" />
                </svg>
              </button>
            </div>
            <div className="subscriptions__row" aria-hidden="true">
              <Price amount={sub.monthlyprice} useKr={true} testIdPrefix="abonnements" />
              /mnd
            </div>
          </div>
          <div className="subscriptions__row__router flex--column-center">
            <div className="subscriptions__row">
              <MobileBroadbandIcon />
              <span className="text--bold-600">{sub.routername}</span>
            </div>
            <div aria-hidden="true">
              <Price amount={sub.routerprice} useKr={true} />
            </div>
            <div className="visually-hidden">{`som koster ${sub.routerprice} kroner`}</div>
          </div>
        </div>
      </li>
    </Fragment>
  );
};
